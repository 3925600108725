


























import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { customerGridActionList } from '@/utils/gridActions/customerGridActionList';
import {
  customerRouteNew,
  customerRouteShow,
  customerTabAttributes,
} from '@/views/definitions/customer/routes';
import { MCustomer } from '@/models/MCustomer';
import { mixinAlert } from '@/components/mixins/alert';
import { modes } from '@/utils/TModes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'CustomerShow',

  components: {
    BaseGridTable,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
  ],

  data() {
    return {
      gridActionList: customerGridActionList,

      // the "actions" column setup is managed by the BaseGrid
      gridColumnDefinition: [
        {
          headerName: this.$t('customer.table.column.customerName'),
          field: 'name',
        },
        {
          headerName: this.$t('customer.table.column.address'),
          field: 'address',
        },
        {
          headerName: this.$t('customer.table.column.company'),
          field: 'companyName',
        },
      ],

      customerRouteShow,
    };
  },

  computed: {
    customerList(): Array<MCustomer> {
      return MCustomer.all();
    },

    gridRowData(): Array<any> {
      return this.customerList.map(
        customer => customer.asListData,
      );
    },
  },

  methods: {
    async add(): Promise<any> {
      const newCustomerID = await MCustomer._create();

      this.$router.push({
        name: customerTabAttributes(modes.EDIT, this.$i18n.locale).name,

        params: {
          ID: newCustomerID,
        },
      });
    },

    edit(customerID: string) {
      this.$router.push({
        name: customerTabAttributes(modes.EDIT, this.$i18n.locale).name,

        params: {
          ID: customerID,
        },
      });
    },

    async remove(customerID: string): Promise<any> {
      const customer = MCustomer.find(customerID);

      const confirmation = await this.confirmAlertDelete(
        this.$t('customer.alert.delete', {
          customerName: `${customer.FirstName} ${customer.LastName}`,
        }),
      );

      if (confirmation.value) {
        MCustomer._delete(customerID);
      }
    },
  },
});
