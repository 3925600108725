

























import BaseTabs from '@/components/base/BaseTabs.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { customerRouteShow } from '@/views/definitions/customer/routes';
import { MCustomer } from '@/models/MCustomer';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModes } from '@/components/mixins/modes';
import { mixinTabList } from '@/components/mixins/tabList';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'CustomerEdit',

  components: {
    BaseTabs,
    LayoutHeader,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModes,
    mixinTabList,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      customerRouteShow,
    };
  },

  computed: {
    currentCustomerName(): string {
      return MCustomer.find(this.ID).name;
    },
  },

  methods: {
    async remove(customerID: string): Promise<any> {
      const customer = MCustomer.find(customerID);

      const confirmation = await this.confirmAlertDelete(
        this.$t('customer.alert.delete', {
          customerName: customer.name,
        }),
      );

      if (confirmation.value) {
        MCustomer._delete(customerID);

        this.$router.push({
          name: customerRouteShow(this.$i18n.locale).name,
        });
      }
    },
  },
});
