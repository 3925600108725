

































































































































import BaseAddress from '@/components/base/BaseAddress.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import uuid4 from 'uuid/v4';
import Vue, { VueConstructor } from 'vue';
import { CONTEXT_NAME } from '@/utils/contextName';
import { genderTypes } from '@/utils/genderTypes';
import { IAddress, initAddress } from '@/typings/interface/IAddress';
import { MAddress } from '@/models/MAddress';
import { MCustomer } from '@/models/MCustomer';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'CustomerAttributes',

  components: {
    BaseAddress,
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    BaseSelect,
    LayoutHeader,
    LayoutSection,
  },

  mixins: [
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      addressKey: 0,
      CONTEXT_NAME,
      genderTypes,
    };
  },

  computed: {
    currentCustomer: {
      get(): MCustomer {
        return MCustomer.query().withAllRecursive().find(this.ID);
      },

      async set(customer: MCustomer) {
        this.dirty = true;

        await MCustomer.update({
          where: this.ID,

          data: customer,
        });
      },
    },

    currentCustomerAddress: {
      get(): IAddress {
        if (this.currentCustomer.Address !== null) {
          return this.currentCustomer.Address;
        }

        return initAddress();
      },

      set(address: IAddress) {
        if (!utIsEmpty(address)) {
          this.dirty = true;

          if (address.Id !== null) {
            MAddress.update({
              data: address,
            });
          } else {
            MAddress.create({
              data: {
                ...address,
                Id: uuid4(),
                OwnerID: this.currentCustomer.Id,
              },
            });
          }
        }
      },
    },

    currentCustomerGender: {
      get() {
        if (this.currentCustomer?.Sex === 'male') {
          return {
            displayName: this.$tc('common.gender.male'),
            name: this.currentCustomer.Sex,
          };
        }

        return {
          displayName: this.$tc('common.gender.female'),
          name: this.currentCustomer.Sex,
        };
      },

      set(value): void {
        this.currentCustomer = {
          ...this.currentCustomer,
          Sex: value,
        };
      },
    },

    gender(): Array<any> {
      return genderTypes.map(gender => {
        if (gender.name === 'male') {
          return {
            displayName: this.$tc('common.gender.male'),
            name: 'male',
          };
        } else {
          return {
            displayName: this.$tc('common.gender.female'),
            name: 'female',
          };
        }
      });
    },
  },

  methods: {
    sync(): void {
      MCustomer._update(this.currentCustomer);
    },

    updateCustomer(prop, value): void {
      if (this.currentCustomer[prop] !== value) {
        this.currentCustomer = {
          ...this.currentCustomer,
          [`${prop}`]: value,
        };
      }
    },
  },
});
