































import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import uuid4 from 'uuid/v4';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MCustomerProductPrice } from '@/models/MCustomerProductPrice';
import { MProduct } from '@/models/MProduct';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'CustomerProducts',

  components: {
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    LayoutHeader,
    LayoutSection,
  },

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  computed: {
    productList() {
      return MProduct.all();
    },
  },

  methods: {
    currentProductPrice(productId): string {
      const productPrice = MCustomerProductPrice.query().where(productPrice => {
        return productPrice.CustomerID === this.ID && productPrice.ProductID === productId;
      }).first();

      if (productPrice) {
        return productPrice.Price;
      }

      return '0';
    },

    updateProductPrice(productId, value): void {
      const productPrice = MCustomerProductPrice.query().where(productPrice => {
        return productPrice.CustomerID === this.ID && productPrice.ProductID === productId;
      }).first();

      if (productPrice) {
        MCustomerProductPrice.update(
          {
            ...productPrice,
            Price: value,
          },
        );
      } else {
        if (value !== '0' && value !== '0.00') {
          const newProductPrice = {
            Id: uuid4(),
            CustomerID: this.ID,
            ProductID: productId,
            Price: value,
          };

          MCustomerProductPrice.insert({
            data: newProductPrice,
          });
        }
      }
    },

    async sync(productId): Promise<any> {
      const productPrice = MCustomerProductPrice.query().where(productPrice => {
        return productPrice.CustomerID === this.ID && productPrice.ProductID === productId;
      }).first();

      MCustomerProductPrice._update(productPrice);
    },
  },
});
